import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import { GISAccordion } from "@app/products/property/components/gis-accordion/_index";
import { Lineage } from "@app/products/property/components/lineage/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  eAccordion,
  eOutputComponentLineage,
} from "@app/products/property/model";
import { ParcelAttributesUDA } from "@app/products/property/parcels/[id]/components/child-screens/attributes-uda/_index";
import { ParcelCertificates } from "@app/products/property/parcels/[id]/components/child-screens/certificates/_index";
import { ParcelConstraints } from "@app/products/property/parcels/[id]/components/child-screens/constraints/_index";
import { ParcelEntities } from "@app/products/property/parcels/[id]/components/child-screens/entities/_index";
import { PropertyParcelForm } from "@app/products/property/parcels/[id]/components/child-screens/general/_index";
import { ParcelLandUses } from "@app/products/property/parcels/[id]/components/child-screens/land-uses/_index";
import { ParcelOwnership } from "@app/products/property/parcels/[id]/components/child-screens/ownership/_index";
import { ParcelTitles } from "@app/products/property/parcels/[id]/components/child-screens/titles/_index";
import { ParcelZones } from "@app/products/property/parcels/[id]/components/child-screens/zones/_index";
import { EditGISReferenceButton } from "@app/products/property/parcels/[id]/components/forms/existed/components/buttons/work-flow/edit-gis-reference/_index";
import { ParcelsAssociationsTab } from "@app/products/property/parcels/[id]/components/reference-sidebar/associations/_index";
import { ParcelDetailTab } from "@app/products/property/parcels/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { ModifyParcelButton } from "@app/products/property/parcels/components/action-bar/buttons/modify-parcel/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const ExistedPropertyParcel = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { parcel, parcelId, isLoading, responseLoadError, loadParcelSummary } =
    useParcelsStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const { isLLS, isActro, isGeneral } = CommunityProperty.getFlagOfStates();
  //Get labels
  const titlesLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Titles
  );

  const bookmarkList = [
    {
      Text: getDisplayTextWithDashes([
        parcel?.LegalDescription,
        parcel?.PropertyName_Address_Locality,
      ]),
      LinkUrl: managePageUrl,
      LinkText: `Property - Parcels - ${parcelId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: parcelId ?? 0,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Parcel_Actions_And_Workflows,
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Parcel}
          componentNumber={eComponent.Parcel}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      accordionId: eAccordion.Parcel_Ownership,
      title: "Ownership",
      component: <ParcelOwnership />,
    },
    {
      accordionId: eAccordion.Parcel_Land_Uses,
      title: "Land Uses",
      component: <ParcelLandUses />,
    },
    {
      accordionId: eAccordion.Parcel_Contacts,
      title: "Contacts",
      component: <ParcelEntities />,
    },
    {
      accordionId: eAccordion.Parcel_Collections,
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.Parcel}
        />
      ),
    },
    {
      accordionId: eAccordion.Parcel_Title,
      title: titlesLabel,
      component: <ParcelTitles />,
    },
    {
      accordionId: eAccordion.Parcel_Constraints,
      title: "Constraints",
      component: <ParcelConstraints />,
      isVisible: false,
    },
    {
      accordionId: eAccordion.Parcel_Zones,
      title: "Zones",
      component: <ParcelZones />,
      isVisible: !isGeneral,
    },
    {
      accordionId: eAccordion.Parcel_Attributes,
      title: "Attributes",
      component: <ParcelAttributesUDA />,
      isVisible: !isActro && !isLLS,
    },
    {
      accordionId: eAccordion.Parcel_Certificates,
      title: "Certificates",
      component: <ParcelCertificates />,
    },
    {
      accordionId: eAccordion.Parcel_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Parcel}
        />
      ),
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit id={parseInt(id)} componentNumber={eComponent.Parcel} />
    //   ),
    // },
    {
      accordionId: eAccordion.Parcel_Lineage,
      title: "Lineage",
      component: (
        <Lineage
          apiUrl={`api/property/internal/parcel/${parcel?.ParcelId}/lineage/${eOutputComponentLineage.Parcel}`}
          urlDetail={PROPERTY_PARCEL_ROUTE}
        />
      ),
    },
    {
      accordionId: eAccordion.Parcel_Related,
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          componentNumber={eComponent.Parcel}
        />
      ),
    },
    {
      accordionId: eAccordion.Parcel_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Parcel}
        />
      ),
    },
    {
      accordionId: eAccordion.Parcel_GIS_References,
      title: "GIS References",
      component: (
        <GISAccordion
          apiUrl={`/api/property/int/gisreference/${
            eComponent.Parcel
          }/${parseInt(id)}`}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Parcel" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadParcelSummary(+id)}
        />
      ) : (
        <>
          <FormTitle title={parcel?.PropertyName_Address_Locality} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Parcel"} type="dropdown">
                  <ModifyParcelButton />
                </CCNavButton>
                <EditGISReferenceButton />
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Parcel}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Parcel}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Parcel}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Parcel}
                  recordType={RECORDTYPE.CommunityProperty_Parcel}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {parcel && (
                  <CCGeneralPanel component={<PropertyParcelForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Parcel}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <ParcelDetailTab id={id} />,
                    },
                    {
                      title: "Map",
                      component: (
                        <MapTab
                          address={
                            parcel?.Addresses && parcel?.Addresses.length === 1
                              ? parcel?.Addresses[0]
                                  .PropertyName_Address_Locality
                              : (parcel?.Addresses &&
                                  parcel?.Addresses.length > 1 &&
                                  parcel?.Addresses?.find(
                                    (address) =>
                                      address.IsPrimaryAddress === true
                                  )?.PropertyName_Address_Locality) ||
                                ""
                          }
                        />
                      ),
                    },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={parcelId}
                          componentNumber={eComponent.Parcel}
                        />
                      ),
                    },
                    {
                      title: "Related",
                      component: <ParcelsAssociationsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Parcel}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedPropertyParcel;
