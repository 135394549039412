import {
  Contact_Relation,
  Svc_BubbleUpIdentifier,
} from "@app/core/inspections/[id]/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import {
  ConditionCategory,
  ConditionType,
} from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Address } from "@common/input-pickers/address/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Keyword } from "@common/models/keyword";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface Condition extends DTOBaseClass_Standard {
  Condition_ID: number;
  Title: string;
  ConditionText: string;
  Clause: string;
  Group: string;
  IsAuto: boolean;
  Condition_Status: string;
  Flag_ConditionUpdated: boolean | null;
  Flag_ConditionRemoved: boolean | null;
  Display_SuppressReason_KWD: number | null;
  Display_SuppressReason_Name: string;
  Display_SuppressComment: string;
  Flag_DisplayInView: boolean | null;
  Removal_Justification: string;
  DateRemoved: Date | null;
  UpdatedEffectiveDate: Date | null;
  DateReview: Date | null;
  Flag_Review: boolean | null;
  AdditionalDetails: string;
  ReviewPeriod: string;
  Category_KWD: number | null;
  Category_Name: string;
  Source_KWD: number | null;
  Source_Name: string;
  SourceRemoval_KWD: number | null;
  SourceRemoval_Name: string;
  ProhibitionSourceRecords: string;
  Lst_SourceRecords: IKeyValuePacket[];
  Lst_ActionSourceRecords: number[];
  ConditionReference_ID: number | null;
  SortIndex: number;
  ConditionType_ENUM: ConditionType | null;
  ConditionType_Name: string;
  DateCreated: Date | null;
  DateCommenced: Date | null;
  DateClosed: Date | null;
  ConditionCategory_ENUM: ConditionCategory | null;
  ConditionCategory_Name: string;
  AllowPublish: boolean | null;
  PublicRegisterDescription: string;
  Notes: string;
  Theme_KWD: number | null;
  ThemeName: string;
  DateDue: Date | null;
  ApproverManager: SiteUser;
  ApproverManager_ID: number | null;
  DateApproved: Date | null;
  ActionOfficer: SiteUser;
  ActionOfficer_ID: number | null;
  PublishingStatus_ENUM: number | null;
  PublishingStatus_Name: string;
  PublishedDetails: string;
  BubbleUps: Svc_BubbleUpIdentifier[];
  _BubbleUps?: Svc_BubbleUpIdentifier[];
  SaveTriggers: ConditionUpdateTriggers[];
}

export enum ConditionUpdateTriggers {
  Save,
  SaveAndExit,
  PublishToRegister,
  PublishToWeb,
  UpdateBubble,
  UpdateConditionManagerForApproval,
  ConditionApproved,
  ConditionDeclined,
}

export interface Svc_Condition_Group {
  Group: string;
  ProductType_ENUM: number | null;
}

export interface Svc_Condition_Lovs {
  Groups: Svc_Condition_Group[];
  Category: IKeyValuePacket[];
}

export interface IParentConditionSection {
  id: number;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  bubbleUps?: Svc_BubbleUpIdentifier[];
  data?: Condition;
  notification?: IAppNotificationItemAddProps[];
  isCondition?: boolean;
  application?: Application;
  registration?: Registration;
  isLockCategory?: boolean;
}

export enum RegistrationStatus {
  Unspecified = 0,
  Draft = 1,
  Current = 2,
  Revoked = 3,
  Lapsed = 4,
  Closed = 5,
  Withdrawn = 6,
  Reject = 7,
  Moved = 8,
}

export enum RegistrationCategory {
  Unspecified = 0,
  DisabilityServiceProvider = 1,
  DisabilityServiceProviderNDIS = 2,
  OutOfHomeCare = 3,
  CommunityBasedChildAndFamilyServices = 4,
  PrescribedCategoryOfService = 5,
  SRS = 6,
}

export enum FacilityApplicationStatus {
  Unspecified = 0,
  Prelodgement = 1,
  RegistrationInProgress = 2,
  Withdrawn = 3,
  Approved = 4,
  NotApproved = 5,
}

export enum FacilityStatus {
  Unspecified = 0,
  Application = 1,
  CurrentOperational = 2,
  CurrentNotOperational = 3,
  CurrentForApproval = 4,
  CurrentUnderAdministration = 5,
  NonCurrentClosed = 6,
  NonCurrentUnderAdministration = 7,
}

export enum ClosureType {
  Unspecified = 0,
  Temporary = 1,
  Permanent = 2,
}

export interface RegistrationClosure extends DTOBaseClass_Standard {
  RegistrationClosure_ID: number;
  Registration_ID: number;
  Organisation_ID: number;
  OrganisationName: string;
  ReasonForClosure: string;
  ClosureType_ENUM: ClosureType;
  ClosureType_Name: string;
  DateProposed: Date | null;
  DateClosed: Date | null;
}

export enum FacilityTenancy {
  Unspecified = 0,
  Leasehold = 1,
  Freehold = 2,
  Unknown = 3,
}

export enum FacilityUpdateTriggers {
  UpdateApplication_Proprietor,
  UpdateFacility_Landlord,
  UpdateFacility_Manager,
  UpdateFacility_Director,
  UpdateFacility_PSC,
  ClearApplication_Proprietor,
  ClearFacility_Director,
  ClearFacility_Landlord,
  ClearFacility_Manager,
  ClearFacility_PSC,
  UpdateBusinessAddress,
  UpdateMailingAddress,
  ClearBusinessAddress,
  ClearMailingAddress,
}

export interface Facility {
  Registration_ID: number;
  Organisation_ID: number;
  OrganisationName: string;
  PreviousOrganisation_ID: number | null;
  PreviousOrganisationName: string;
  Application_Number: string;
  Application_Proprietor_RID: number | null;
  Application_Proprietor: Contact_Relation;
  Location_PrimaryContact_RID: number | null;
  Location_PrimaryContact: Contact_Relation;
  Application_DateReceived: Date | null;
  Application_Status_ENUM: FacilityApplicationStatus;
  Application_Status_Name: string;
  Application_DateStatCompletion: Date | null;
  Application_DateActualCompletion: Date | null;
  Registration_Number: string;
  Registration_DateApproval: Date | null;
  Registration_CertNumber: string;
  Registration_DateCertIssued: Date | null;
  Registration_AuthBedNumbers: number | null;
  Facility_Number: string;
  Facility_Status_ENUM: FacilityStatus;
  Facility_Status_Name: string;
  Facility_DateOpened: Date | null;
  Facility_DateClosed: Date | null;
  Facility_Name: string;
  Facility_Address_ID: number | null;
  Facility_Address: Address;
  Facility_PostalAddress_ID: number | null;
  Facility_PostalAddress: Address;
  Facility_Phone: string;
  Facility_Fax: string;
  Facility_Email: string;
  Facility_Occupancy: number | null;
  Facility_TotalRegBeds: number | null;
  Facility_PensionBeds: number | null;
  Facility_SAVVIFundedBeds: number | null;
  Facility_SingleBedrooms: number | null;
  Facility_SingleEnsuites: number | null;
  Facility_CommunalBathrooms: number | null;
  Facility_DoubleBedrooms: number | null;
  Facility_DoubleEnsuites: number | null;
  Facility_FireRiskArea_KWD: number | null;
  Facility_Division_IDs: number[];
  Facility_Divisions_CSV: string;
  Facility_LGA_KWD: number | null;
  Facility_StateGovernmentAssisted_KWD: number | null;
  Facility_AssignedTo_ID: number | null;
  Facility_AssignedTo_Name: string;
  Facility_AssignedTo_Email: string;
  Facility_Landlord_RID: number | null;
  Facility_Landlord: Contact_Relation;
  Facility_Director_RID: number | null;
  Facility_Director: Contact_Relation;
  ASIC_Number: string;
  ASIC_DateSearch: Date | null;
  ASIC_SearchType: string;
  SAVVI_PropDirectCare: number | null;
  SAVVI_OtherDirectCare: number | null;
  SAVVI_AmenitySafety: boolean | null;
  SAVVI_TrainingParticipation: boolean | null;
  RegistrationAdministration_ID: number | null;
  RegistrationClosure_ID: number | null;
  RegistrationClosure: RegistrationClosure;
  Facility_Manager_RID: number | null;
  Facility_Manager: Contact_Relation;
  Facility_PSC_RID: number | null;
  Facility_PSC: Contact_Relation;
  Facility_Alert: string;
  Registration_RequiresCert: boolean | null;
  Registration_RecordCommitted: boolean | null;
  Agency_KWD: number | null;
  Registration_Tenancy_ENUM: FacilityTenancy;
  Registration_Tenancy_Name: string;
  Registration_Pilot: boolean | null;
  Application_CL_BuildingReq: boolean | null;
  Application_CL_OperationalReq: boolean | null;
  Application_CL_PersonnelReq: boolean | null;
  Application_CL_OtherReq: boolean | null;
  Application_CL_DateBuildingRequested: Date | null;
  Application_CL_DateOperationalRequested: Date | null;
  Application_CL_DatePersonnelRequested: Date | null;
  Application_CL_DateOtherRequested: Date | null;
  Application_CL_DateBuildingReceived: Date | null;
  Application_CL_DateOperationalReceived: Date | null;
  Application_CL_DatePersonnelReceived: Date | null;
  Application_CL_DateOtherReceived: Date | null;
  Application_CL_BuildingPassed: boolean | null;
  Application_CL_OperationalPassed: boolean | null;
  Application_CL_PersonnelPassed: boolean | null;
  Application_CL_OtherPassed: boolean | null;
  SAVVI_PropHours: number | null;
  SAVVI_PaidStaffHours: number | null;
  SAVVI_OtherStaffHours: number | null;
  SAVVI_TotalHours: number | null;
  Facility_TripleBedrooms: number | null;
  Facility_QuadBedrooms: number | null;
  Facility_FireStatus_KWD: number | null;
  Facility_Vacancies: number | null;
  Risk_ID: number | null;
  Conditions: string;
  Conditions_2: string;
  Conditions_3: string;
  ProprietorCommencedDate: Date | null;
  Date_SAVVIReportDue: Date | null;
  Date_SAVVIReportReceived: Date | null;
  PlannedMonitoring_KWD: number | null;
  ClientType_KWDs: number[];
  ClientTypes_CSV: string;
  DateNextStatement: Date | null;
  IssueCategory_KWD: number | null;
  ReportableIncident_DateReceived: Date | null;
  ReportableIncident_DateOccurred: Date | null;
  ReportableIncident_Description: string;
  ReportableIncident_Complainant: Contact_Relation;
  ReportableIncident_Complainant_Contact_RID: number | null;
  ReportableIncident_Complainant_Name: string;
  SaveTriggers: FacilityUpdateTriggers[];
}

export enum EnforcementOptions {
  EnforcementPlan = 0,
  ComplianceNotice = 1,
  Infringement = 2,
  SuspendAdmissions = 3,
  AppointAdmin = 5,
  RegistrationStatement = 6,
  Undertaking = 7,
  Censure = 8,
  Sanction = 9,
  SearchWarrant = 10,
  Prosecution = 11,
}

export enum RegistrationUpdateTriggers {
  UpdatePrimaryContact,
  UpdateBusinessAddress,
  ClearBusinessAddress,
  UpdateMailingAddress,
  ClearMailingAddress,
  UpdateCategories,
  UpdateOrganisationCategories,
  UpdateConditions,
  UpdateClosure,
}

export interface Registration extends DTOBaseClass_Standard {
  Registration_ID: number;
  Organisation_ID: number;
  PreviousOrganisation_ID: number | null;
  Status_ENUM: RegistrationStatus;
  Status_Name: string;
  DateRegistrationCommencement: Date | null;
  DateRegistrationExpires: Date | null;
  LastAccreditationCertificate_ID: number | null;
  Category_ENUMS: RegistrationCategory[];
  Category_KWDs: number[];
  Categories_CSV: string;
  DisabilityCategories_CSV: string;
  CYFCategories_CSV: string;
  SRSCategories_CSV: string;
  AllRegistrationCategories: Keyword[];
  CurrentRegistrationCategories: Keyword[];
  Division_IDs: number[];
  Divisions_CSV: string;
  Registration_AssignedTo_ID: number | null;
  Registration_AssignedTo_Name: string;
  Registration_AssignedTo_Email: string;
  HasDisabilityService: boolean;
  HasCYFService: boolean;
  HasSRSService: boolean;
  Conditions: Condition[];
  OrganisationName: string;
  BusinessAddress: string;
  MailingAddress: string;
  PrimaryContact: string;
  PrimaryContact_FirstName: string;
  PrimaryContact_LastName: string;
  Phone: string;
  Email: string;
  OrganisationHssScope: string;
  OrganisationRegisteredProvider: string;
  OrganisationHasRenewalApplication: boolean;
  OrganisationFileNumber: string;
  PreviousOrganisationName: string;
  Location_ID: number | null;
  Location: Location;
  HasRevocationApplication: boolean;
  DateRevoked: Date | null;
  DateClosed: Date | null;
  RevokedBy_ID: number | null;
  RevokedBy_DisplayName: string;
  RevokedReason_KWD: number | null;
  RevokedReason_Name: string;
  RevokedComments: string;
  DateLapsed: Date | null;
  LapsedBy_ID: number | null;
  LapsedBy_DisplayName: string;
  LapsedReason_KWD: number | null;
  LapsedReason_Name: string;
  LapsedComments: string;
  ShowOnRevocationsPublicRegister: boolean | null;
  Facility: Facility;
  DateLastInspected: Date | null;
  DateNextInspection: Date | null;
  Enforcement_SelectedOption: EnforcementOptions | null;
  RelatedIssue_ID: number | null;
  Risk_ID: number | null;
  Registration_DateApproval: Date | null;
  SaveTriggers: RegistrationUpdateTriggers[];
  FacilityFacility_TripleBedrooms: any;
  HasChangeOfDetailsApplication: boolean;
  HasChangeOfDetails_SRS_Application: boolean;
  IsPortal: boolean;
}

export enum RegisteredProvider {
  Unspecified = 0,
  CYF = 1,
  Disability = 2,
  CYFAndDisability = 3,
  NotApplicable = 4,
  SRS = 5,
}

export enum HumanServicesStandardScope {
  Unspecified = 0,
  IndependentReview = 1,
  SelfAssessment = 2,
  Exempt = 3,
  Pending = 4,
  NotApplicable = 5,
}

export enum ApplicationStatus {
  Unspecified = 0,
  Draft = 1,
  Submitted = 2,
  OfficerRecommendedForApproval = 3,
  OfficerRecommendedForRefusal = 4,
  ManagerEndorsedForApproval = 5,
  ManagerEndorsedForRefusal = 6,
  ManagerSentBack = 7,
  DelegateSentBack = 8,
  OfficerSendBackRecommendedForApproval = 9,
  OfficerSendBackRecommendedForRefusal = 10,
  DelegateProposedRefusal = 11,
  RefusalProposedNotificationSent = 12,
  RefusalProposedNotificationAcknowledged = 13,
  RefusalProposedResponseReceived = 14,
  PanelRecommendedForApproval = 15,
  PanelRecommendedForRefusal = 16,
  Approved = 17,
  Refused = 18,
  Cancelled = 19,
  DelegateProposedApproval = 20,
  ApprovalProposedNotificationSent = 21,
  ApprovalProposedNotificationAcknowledged = 22,
  ApprovalProposedResponseReceived = 23,
  ApprovalProposedResponseNotReceived = 24,
  ApprovalProposedResponseSatisfactory = 25,
  ApprovalProposedResponseUnsatisfactory = 26,
}

export enum ApplicationType {
  Unspecified = 0,
  NewRegistration_CYFDIS = 1,
  RegistrationRenewal_CYFDIS = 2,
  ChangeOfDetails_Organisation = 3,
  NewCondition = 4,
  RegistrationRevocation = 5,
  NewRegistration_SRS = 6,
  SuspendAdmission = 7,
  Infringement = 8,
  EnforcementPlan = 10,
  PrescribedReportableIncident = 11,
  SearchWarrant = 12,
  ChangeOfDetails_SRS = 13,
}

export enum ApplicationMetadataUpdateTriggers {
  UpdateApplicant,
  UpdateCEO,
  UpdatePrimaryContact,
  UpdateStreetAddress,
  UpdatePostalAddress,
  UpdateCategories,
}

export interface ApplicationMetadata {
  SaveTriggers: ApplicationMetadataUpdateTriggers[];
}

export enum ApplicationUpdateTriggers {
  UpdateApplicant,
  UpdateCEO,
  UpdatePrimaryContact,
  UpdateStreetAddress,
  UpdatePostalAddress,
  UpdateCategories,
  UpdateConditions,
  UpdateEnforcementPlan,
  UpdateReportableIncident,
}

export interface Application extends DTOBaseClass_Standard {
  Application_ID: number;
  Organisation_ID: number;
  Organisation_Name: string;
  Organisation_ContactName: string;
  Organisation_ContactEmail: string;
  IsSRSOrganisation: boolean | null;
  OrganisationRegisteredProvider_ENUM: RegisteredProvider[];
  OrganisationRegisteredProvider_CSV: string;
  IsDisabilityProvider: boolean;
  IsCYFProvider: boolean;
  IsSRSProvider: boolean;
  OrganisationIsRegistered: boolean;
  OrganisationHssScope_ENUM: HumanServicesStandardScope | null;
  OrganisationHssScope_Name: string;
  Organisation_FileNumber: string;
  Registration_ID: number | null;
  RegistrationDescription: string;
  Status_ENUM: ApplicationStatus;
  Status_Name: string;
  Type_ENUM: ApplicationType;
  Type_Name: string;
  Metadata: ApplicationMetadata;
  Reviewer_ID: number;
  Reviewer_Name: string;
  Reviewer_Email: string;
  Officer_ID: number;
  Officer_Name: string;
  Officer_Email: string;
  Manager_ID: number;
  Manager_Name: string;
  Manager_Email: string;
  Delegate_ID: number;
  Delegate_Name: string;
  Delegate_Email: string;
  PanelMember_ID: number | null;
  PanelMember_Name: string;
  PanelMember_Email: string;
  DateCreated: Date;
  DateApplicationReceived: Date;
  DateOfficerProcessingStarted: Date | null;
  DateOfficerProcessingCompleted: Date | null;
  OfficerRecommendedApproval: boolean | null;
  OfficerRecommendationNotes: string;
  DateRegistrationCommencement: Date | null;
  DateRegistrationExpiry: Date | null;
  ManagerEndorsedApproval: boolean | null;
  EndorsedByManager_ID: number | null;
  EndorsedByManager_Name: string;
  DateEndorsedByManager: Date | null;
  EndorsedByManagerNotes: string;
  SentBackByManager: boolean | null;
  SentBackByManager_ID: number | null;
  SentBackByManager_Name: string;
  DateSentBackByManager: Date | null;
  SentBackByManagerNotes: string;
  SentBackByDelegate: boolean | null;
  SentBackByDelegate_ID: number | null;
  SentBackByDelegate_Name: string;
  DateSentBackByDelegate: Date | null;
  SentBackByDelegateNotes: string;
  DateOfficerSentBackProcessingStarted: Date | null;
  DateOfficerSentBackProcessingCompleted: Date | null;
  OfficerSentBackRecommendedApproval: boolean | null;
  OfficerSentBackRecommendationNotes: string;
  RefusalProposedByDelegate: boolean | null;
  RefusalProposedByDelegate_ID: number | null;
  RefusalProposedByDelegate_Name: string;
  RefusalProposedByOnBehalfOfUser: boolean | null;
  RefusalProposedByOnBehalfOfUser_ID: number | null;
  RefusalProposedByOnBehalfOfUser_Name: string;
  DateRefusalProposedByDelegate: Date | null;
  DelegateRefusalProposedNotes: string;
  RefusalProposedNotificationSent: boolean | null;
  DateRefusalProposedNotificationSent: Date | null;
  RefusalProposedNotificationSentBy_ID: number | null;
  RefusalProposedNotificationSentBy_Name: string;
  RefusalProposedNotificationSentMethod: string;
  RefusalProposedNotificationSentEmail: string;
  RefusalProposedAcknowledged: boolean | null;
  DateRefusalProposedAcknowledged: Date | null;
  RefusalProposedResponseReceived: boolean | null;
  DateRefusalProposedResponseReceived: Date | null;
  DatePanelReevaluationStarted: Date | null;
  DatePanelReevaluationCompleted: Date | null;
  PanelReevaluationRecommendedApproval: boolean | null;
  PanelReevaluationRecommendationNotes: string;
  PanelReevaluationBy_ID: number | null;
  PanelReevaluationBy_Name: string;
  ApprovalProposedByDelegate: boolean | null;
  ApprovalProposedByDelegate_ID: number | null;
  ApprovalProposedByDelegate_Name: string;
  DateApprovalProposedByDelegate: Date | null;
  DelegateApprovalProposedNotes: string;
  ApprovalProposedNotificationSent: boolean | null;
  DateApprovalProposedNotificationSent: Date | null;
  ApprovalProposedNotificationSentBy_ID: number | null;
  ApprovalProposedNotificationSentBy_Name: string;
  ApprovalProposedNotificationSentMethod: string;
  ApprovalProposedNotificationSentEmail: string;
  ApprovalProposedAcknowledged: boolean | null;
  DateApprovalProposedAcknowledged: Date | null;
  ApprovalProposedResponseReceived: boolean | null;
  DateApprovalProposedResponseReceived: Date | null;
  ApprovalProposedResponseSatisfactory: boolean | null;
  DateApprovalProposedResponseAssessed: Date | null;
  OfficerApprovalProposedResponseAssessedNotes: string;
  DateApprovalProposedResponseNotReceived: Date | null;
  OfficerApprovalProposedResponseNotReceivedNotes: string;
  RefusedByDelegate: boolean | null;
  RefusedByDelegate_ID: number | null;
  RefusedByDelegate_Name: string;
  RefusedByOnBehalfOfUser: boolean | null;
  RefusedByOnBehalfOfUser_ID: number | null;
  RefusedByOnBehalfOfUser_Name: string;
  DateRefusedByDelegate: Date | null;
  RefusedByManager: boolean | null;
  RefusedByManager_ID: number | null;
  RefusedByManager_Name: string;
  DateRefusedByManager: Date | null;
  ApprovedByDelegate: boolean | null;
  ApprovedByDelegate_ID: number | null;
  ApprovedByDelegate_Name: string;
  ApprovedByOnBehalfOfUser: boolean | null;
  ApprovedByOnBehalfOfUser_ID: number | null;
  ApprovedByOnBehalfOfUser_Name: string;
  DateApprovedByDelegate: Date | null;
  DelegateCompletionNotes: string;
  ManagerCompletionNotes: string;
  ApprovedByOfficer: boolean | null;
  ApprovedByOfficer_ID: number | null;
  ApprovedByOfficer_Name: string;
  DateApprovedByOfficer: Date | null;
  ApprovedByOfficerNotes: string;
  CompletedNotificationSent: boolean | null;
  DateCompletedNotificationSent: Date | null;
  CompletedNotificationSentBy_ID: number | null;
  CompletedNotificationSentBy_Name: string;
  CancelledBy_ID: number | null;
  CancelledBy_Name: string;
  DateCancelled: Date | null;
  CancelledNotes: string;
  AllRegistrationCategories: Keyword[];
  Conditions: Condition[];
  FeedbackToProvider: string;
  BubbleUps: BubbleUpIdentifier[];
  PanelMembers_ID: number[];
  PanelMembers_CSV: string;
  SaveTriggers: ApplicationUpdateTriggers[];
  IsPortal: boolean;
}

export enum ServiceAgreementStatus {
  Unspecified = 0,
  NoServiceAgreement = 1,
  HasServiceAgreement = 2,
  Contingency = 3,
}

export enum AccommodationServices {
  Unspecified = 0,
  Yes = 1,
  No = 2,
  Disability = 3,
  OutOfHomeCare = 4,
}

export enum AccommodationLength {
  Unspecified = 0,
  ShortStay = 1,
  LongStayIncludingRespite = 2,
  Residential = 3,
  Other = 4,
}

export interface MetadataAccommodationService {
  SiteAddress_ID: number;
  SiteAddress: Address;
  ServiceDetails: string;
  NumberOfClients: number | null;
  NumberOfBeds: number | null;
  AccommodationLength_ENUM: AccommodationLength | null;
  AccommodationLength_Name: string;
  AccommodationLengthOtherDetails: string;
  PrivatelyOwned: boolean | null;
  PrivatelyOwnedDetails: string;
  OwnedByNotForProfitCommunityOrganisation: boolean | null;
  IncludesProvisionOfSupport: boolean | null;
  ProvisionOfSupportDetails: string;
  IncludesRosteredSupport: boolean | null;
  RosteredSupportDetails: string;
  SupportProviderSameAsAccommodationOwner: boolean | null;
  AccommodationProviderRegisteredWithNdia: boolean | null;
}

export interface ApplicationNewRegistration_Checklist {
  LegalEntity: boolean | null;
}

export interface ApplicationMetadata_NewRegistration
  extends ApplicationMetadata {
  IsLegalEntity: boolean | null;
  LegalEntityName: string;
  Applicant_RID: number | null;
  Applicant: Contact_Relation;
  Applicant_Name: string;
  CEO_RID: number | null;
  CEO: Contact_Relation;
  CEO_Name: string;
  PrimaryContact_RID: number | null;
  PrimaryContact: Contact_Relation;
  PrimaryContact_Name: string;
  HasABN: boolean | null;
  ABN: string;
  ABNValid: boolean;
  AttachedCopyOfABN: boolean | null;
  HasACN: boolean | null;
  ACN: string;
  ACNValid: boolean;
  AttachedCopyOfACN: boolean | null;
  AttachedCopyOfASICCheck: boolean | null;
  Website: string;
  AttachedCopyOfBrochure: boolean | null;
  StreetAddress_ID: number | null;
  StreetAddress: Address;
  StreetAddress_SingleLine: string;
  PostalAddressSameAsStreetAddress: boolean | null;
  PostalAddress_ID: number | null;
  PostalAddress: Address;
  PostalAddress_SingleLine: string;
  Locations: IKeyValuePacket[];
  Location_IDs: number[];
  Location_CSV: string;
  Category_KWDs: number[];
  Category_CSV: string;
  Category_ENUMS: RegistrationCategory[];
  CurrentCategory_KWDs: number[];
  CurrentCategory_CSV: string;
  CurrentCategory_ENUMS: RegistrationCategory[];
  NewCategory_KWDs: number[];
  NewCategories_CSV: string;
  NewCategory_ENUMS: RegistrationCategory[];
  DeselectedCategory_KWDs: number[];
  DeselectedCategory_CSV: string;
  DeselectedCategory_ENUMS: RegistrationCategory[];
  IsDeselectCategoryToBeRevoked: boolean;
  DeselectCategoryRevokedReason_KWD: number | null;
  DeselectCategoryRevokedReason_Name: string;
  HasDisabilityService: boolean;
  HasCYFService: boolean;
  HasSRSService: boolean;
  NewRegisteredProvider_ENUMS: RegisteredProvider[];
  HasOutOfHomeCare: boolean;
  ServiceAgreement_ENUM: ServiceAgreementStatus | null;
  ServiceAgreement_Name: string;
  ServiceAgreementEvidenceAttached: boolean | null;
  HasOtherBusinessNames: boolean | null;
  OtherBusinessNames: string;
  HasSubcontractors: boolean | null;
  SubcontractorsApproved: boolean | null;
  SubcontractorsApprovalEvidenceAttached: boolean | null;
  Subcontractors: IKeyValuePacket[];
  Subcontractor_IDs: number[];
  Subcontractor_CSV: string;
  Division_IDs: number[];
  Divisions_CSV: string;
  DivisionActivities: string;
  AccommodationServices_ENUM: AccommodationServices | null;
  AccommodationServices_Name: string;
  AccommodationServices: MetadataAccommodationService[];
  AccommodationServicesText: string;
  KeyPersonnel: Contact_Relation[];
  KeyPersonnelText: string;
  DisabilityServiceTypes: string;
  CYFServiceTypes: string;
  DisabilitySupportExperience: string;
  DisabilitySupportUnderstanding: string;
  DisabilitySupportQualificationsAttached: boolean | null;
  CYFSupportExperience: string;
  CYFSupportUnderstanding: string;
  CYFSupportQualificationsAttached: boolean | null;
  ComplianceReviewUndertaken: boolean | null;
  ComplianceReviewUndertakenEvidenceAttached: boolean | null;
  ComplianceReviewText: string;
  DateApplicantSignedDeclaration: Date | null;
  DateWitnessSignedDeclaration: Date | null;
  SelfAssessmentCompletedByExternalContact: boolean | null;
  SelfAssessmentCompletedByExternalContactOrganisationName: string;
  SelfAssessmentExternalContactSignedDeclaration: boolean | null;
  Checklist: ApplicationNewRegistration_Checklist;
  Registration_AssignedTo_ID: number | null;
  Registration_AssignedTo_Name: string;
  Registration_AssignedTo_Email: string;
}

export interface Svc_Condition {
  Condition: Condition;
  LockCategory: boolean;
}
