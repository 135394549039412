import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionForm } from "@common/pages/actions/[id]/components/child-screens/general/_index";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { ActionCancelMeetingButton } from "@common/pages/actions/components/action-bar/buttons/cancel-meeting/_index";
import { ActionMeetingCompleteButton } from "@common/pages/actions/components/action-bar/buttons/meeting-complete/_index";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewAction = observer(() => {
  const {
    isLoading,
    isLoadingParent,
    isLoadingAction,
    onSubmit,
    responseLoadError,
    reLoadAction,
  } = useActionStore();
  const { settings } = useCommonCoreStore();

  return (
    <>
      <Loading isLoading={isLoadingParent || isLoadingAction} isFullScreen />
      <FormNavigation title={"Action"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => reLoadAction()}
        />
      ) : (
        <>
          <FormTitle title={"Action"} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                isDefaultActive={getBoolValueSetting(
                  settings[
                    ECorporateSettingsField
                      .CorporateSettings_Action_AlwaysPinOpenActionWorkflowBar
                  ]
                )}
              >
                <ActionMeetingCompleteButton />
                <ActionCancelMeetingButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={isLoading}
                name={ActionSubmitActions.New}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <ActionForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
