import { history } from "@/AppRoutes";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  Permit,
  Permit_Status,
  Svc_Permit,
} from "@app/products/local-laws/[id]/model";
import { getPermitGeneralForm } from "@app/products/local-laws/[id]/util";
import {
  postLodgePermit,
  postSetupLodgePermit,
} from "@app/products/local-laws/components/toolbar/buttons/lodge-permit/api";
import { Svc_Permit_Workflow_LodgePermitRequest } from "@app/products/local-laws/components/toolbar/buttons/lodge-permit/model";
import { LodgeDialog } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/lodge-application/_index";
import { ILodge } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/lodge-application/model";
import { APIResponse } from "@common/apis/model";
import { LLP_NEXTACTION } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { PickDebtorNumberDialog } from "@common/dialog/debtor-number/_index";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface ILodgePermitButtonProps {
  isDisabled?: boolean;
}

export const LodgePermitButton = observer(
  ({ isDisabled }: ILodgePermitButtonProps) => {
    //#region STORE ========/
    const { cancelToken } = useCancelRequest();
    const { dataForms, isLoadingForm, setIsLoadingForm, updateFormFields } =
      useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const isNew = useIsNew();
    const [isShowPickDebtorDialog, setIsShowPickDebtorDialog] = useState(false);
    const [isShowCoreDateDialog, setIsShowCoreDateDialog] = useState(false);
    const [contactId, setContactId] = useState<number>();
    const [dataPermit, setDataPermit] = useState<Permit>();
    //#endregion HOOK =====/

    //#region DEFINED ========/
    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return permitData?.Status_ENUM === Permit_Status.NewApplication;
    }, [dataForms]);
    //#endregion DEFINED =====/

    //#region METHOD ========/
    const setupLodgePermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const permit: Permit = initialData;
            return postSetupLodgePermit(permit, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IIdentityPacket<LLP_NEXTACTION>> =
              dataFromApi;
            const nextAction = response?.data?.ReturnObj;
            if (nextAction === LLP_NEXTACTION.LODGEPERMIT) {
              setIsShowCoreDateDialog(true);
            } else if (nextAction === LLP_NEXTACTION.PICKDEBTOR) {
              setIsShowPickDebtorDialog(true);
            } else {
              pushNotificationPortal({
                title: "Setup for lodge permit failed.",
                type: "error",
                autoClose: false,
              });
            }
          },
          handleError: ({ errorFromApi, initialData }) => {
            pushNotificationPortal({
              title: "Lodge permit failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const lodgePermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const lodgePermitRequest: Svc_Permit_Workflow_LodgePermitRequest =
              initialData;
            return postLodgePermit(lodgePermitRequest, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IIdentityPacket<Permit>> = dataFromApi;
            const newPermit = response?.data?.ReturnObj as Permit;
            if (response?.data?.ReturnObj) {
              if (isNew) {
                setIsShowCoreDateDialog(false);
                const urlRedirect =
                  LOCAL_LAWS_PERMIT_ROUTE + "/" + newPermit.Permit_ID;
                pushNotificationPortal({
                  title: "Permit lodged successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              } else {
                //#region save data to store ========/
                updateFormFields("GeneralForm", {
                  Permit: newPermit,
                } as Svc_Permit);
                setIsShowCoreDateDialog(false);
              }
              //#endregion save data to store =====/
            } else {
              pushNotificationPortal({
                title: "Lodge permit failed.",
                type: "error",
                autoClose: false,
              });
            }
            return false;
          },
          handleError: ({ errorFromApi, initialData }) => {
            pushNotificationPortal({
              title: "Lodge permit failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async (
      _event: any,
      isSetupLode?: boolean,
      debtorNumber?: string
    ) => {
      if (isNil(isSetupLode)) isSetupLode = true;
      const dataForm = getPermitGeneralForm();
      let permit = dataForm?.Permit as Permit;
      if (!isNil(dataForm?.Permit)) {
        permit.TypeOfDescription = permit?.TypeOfDescription ?? "";
        permit.ReferenceNumber = permit?.ReferenceNumber ?? "";
        permit.FileNumber = permit?.FileNumber ?? "";
        permit.InsurancePolicyNumber = permit?.InsurancePolicyNumber ?? "";
        permit.Description = permit?.Description ?? "";
        setContactId(permit?.Contact?.Contact?.Contact_ID);

        if (debtorNumber) {
          permit.DebtorNumber = debtorNumber;
        }
        setDataPermit(permit);

        if (permit) {
          if (isSetupLode) {
            setIsLoadingForm(true);
            await setupLodgePermitButtonSlider.fetchApi({
              initialData: permit,
            });
            setIsLoadingForm(false);
          } else {
            setIsShowCoreDateDialog(true);
          }
        }
      }
    };

    const handlePickDebtor = (value: RefNumberUsage[]) => {
      setIsShowPickDebtorDialog(false);
      handleClickButton(null, false, value?.[0]?.Sys_DebtorNumber);
    };

    const handleSubmitCoreDateDialog = async (value: ILodge) => {
      const lodgePermitRequest = {
        Permit: dataPermit,
        DateLodge: value.DateLodged,
      } as Svc_Permit_Workflow_LodgePermitRequest;
      await lodgePermitButtonSlider.fetchApi({
        initialData: lodgePermitRequest,
      });
    };
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Lodge permit"
          invisible={!isVisible}
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />
        {isShowPickDebtorDialog && (
          <PickDebtorNumberDialog
            onClose={() => {
              setContactId(undefined);
              setIsShowPickDebtorDialog(false);
            }}
            onSubmit={(value: RefNumberUsage[]) => handlePickDebtor(value)}
            contactId={contactId}
            recordId={0}
            recordType={RECORDTYPE.LLP_Permit}
          />
        )}
        {isShowCoreDateDialog && (
          <LodgeDialog
            onClose={() => {
              setIsShowCoreDateDialog(false);
            }}
            onSubmit={handleSubmitCoreDateDialog}
            isLoading={lodgePermitButtonSlider.isFetching}
          />
        )}
      </>
    );
  }
);
